<template>
	<div>
		<f-default-header
			:title="$t('pages.settings.title')"
			:subtitle="$t('pages.settings.subtitle.countries')" />

		<f-list-table />

		<f-actions-btn-holder>
			<v-btn elevation="0" large @click="back">
				{{ $t("labels.btnBack") }}
			</v-btn>
			&nbsp;
			<v-btn color="primary" elevation="0" large @click="create">
				{{ $t("labels.btnNew") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FListTable from "../../../components/settings/country/List.vue";
import { SETTINGS_ROUTES } from "./../../../router/settings";

export default {
	components: {
		FListTable,
	},
	methods: {
		create() {
			this.$router.push({
				name: SETTINGS_ROUTES.COUNTRY_CREATE,
			});
		},
		back() {
			this.$router.push({
				name: SETTINGS_ROUTES.SETTINGS,
			});
		},
	},
};
</script>
