<template>
	<f-card>
		<v-data-table :headers="headers" :items="countries" @click:row="edit" />
	</f-card>
</template>

<script>
import { list } from "./../../../services/country";
import { SETTINGS_ROUTES } from "./../../../router/settings";
import dateFormater from "../../../helpers/date_formater";

export default {
	data() {
		return {
			headers: [
				{
					text: this.$t("pages.settings.country.list.name"),
					align: "start",
					sortable: true,
					value: "name",
				},
				{
					text: this.$t("pages.settings.country.list.createdAt"),
					value: "created_at",
					align: "end",
				},
			],
		};
	},
	computed: {
		countries() {
			return this.$store.state.country.list.map((item) => {
				try {
					const names = JSON.parse(item.name);
					item.name = names[this.currentLanguage];
					item.created_at = dateFormater.fromDB(item.created_at);
					return item;
				} catch (_) {
					return "";
				}
			});
		},
		loading() {
			return !this.$store.state.system.loading.done;
		},
		currentLanguage() {
			return this.$i18n.locale.toLocaleLowerCase();
		},
	},
	created() {
		list();
	},
	methods: {
		edit(jobRole) {
			this.$router.push({
				name: SETTINGS_ROUTES.COUNTRY_EDIT,
				params: {
					id: jobRole.id,
				},
			});
		},
	},
};
</script>
